@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&display=swap");
@import "~cropperjs/dist/cropper.css";

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.invert-colors {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.invisibleScrollNav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.invisibleScrollNav {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.input-range--disabled .input-range__slider {
  font-size: 9rem;
  border: 3px solid #02223b !important;
}

.input-range--disabled .input-range__label-container {
  color: #fff !important;
  top: 2.3rem;
}
.initialSlider .input-range__label--max {
  display: none;
}

.initialSlider .input-range__label--min {
  display: none;
}

.input-range__slider {
  background: #02223b !important;
  border: 3px solid #ffce00 !important;
}

.input-range__track--active {
  background: #ffce00 !important;
}

.projection-mode-box-shadow {
  box-shadow: 0 10px 20px 0 #03375e !important;
}

/* .input-range__label-container {
  color: #fff !important;
  opacity: 72%;
} */

.input-range__track--background {
  background-color: #02223b !important;
}

.input-range__label {
  color: #8798ad !important;
  font-family: "Fira Sans", monospace !important;
}

.tooltip-full-marker {
  width: 0.93rem;
}
.content-panel {
  background-color: #eff2fb;
}
.row-option {
  padding: 15px 6px 52px 12px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}
.dataupload-table {
  border-collapse: separate;
  border-spacing: 0 15px;
}
.vectorvalues-table {
  border-collapse: separate;
  border-spacing: 0 6px;
}
.apexcharts-legend {
  padding-top: 30px !important;
}
/*

.apexcharts-yaxis-title {
  font-family: "Fira Sans", monospace !important;
}

.apexcharts-xaxis-label {
  font-family: "Fira Sans", monospace !important;
  color: #b0bac9 !important;
}

.apexcharts-xaxis-projection-label {
  font-family: "Fira Sans", monospace !important;
  color: #f4f6fc !important;
}

.apexcharts-yaxis-label {
  font-family: "Fira Sans", monospace !important;
  color: #b0bac9 !important;
}

.apexcharts-yaxis-projection-label {
  font-family: "Fira Sans", monospace !important;
  color: #ffffff !important;
}

.apexcharts-tooltip-title {
  font-family: "Fira Sans", monospace !important;
}
.apexcharts-tooltip-series-group {
  font-family: "Fira Sans", monospace !important;
}
.apexcharts-tooltip-text {
  font-family: "Fira Sans", monospace !important;
} */

.MuiChip-outlinedPrimary {
  color: #4db6ac !important;
  border: 1px solid #4db6ac !important;
}

.MuiSelect-select:focus {
  background: none !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #4db6ac !important;
}

#main {
  transition: margin-left 0.5s;
}

#moduleContent {
  transition: margin-right 0.5s;
}

.module-card {
  border: solid 1px rgba(46, 91, 255, 0.08);
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
}

.custom-shadow {
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
}

.font-fira {
  font-family: "Fira Sans", sans-serif !important;
}

.projection-drawer {
  border: solid 1px rgba(46, 91, 255, 0.08);
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
}

/* loading ring spinner */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #bfc5d2;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #bfc5d2 transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* loading spinner */

.title-spacing {
  letter-spacing: 1.21px;
}

.rol-field {
  width: 100% !important;
}

.chip-class {
  border-radius: 5px !important;
}

.active-avatar {
  box-shadow: 0 0px 7px 0 #4db6ac;
}

.bottom-15negative {
  bottom: -8%;
}
